@import url(https://fonts.googleapis.com/css?family=Abril+Fatface|Merriweather|Quando|Shrikhand);@import url(https://fonts.googleapis.com/css?family=Abril+Fatface|Merriweather|Quando|Shrikhand);@import url(https://fonts.googleapis.com/css?family=Abril+Fatface|Merriweather|Quando|Shrikhand);@import url(https://fonts.googleapis.com/css?family=Abril+Fatface|Merriweather|Quando|Shrikhand);@import url(https://fonts.googleapis.com/css?family=Abril+Fatface|Merriweather|Quando|Shrikhand);/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before, blockquote:after {
  content: '';
  content: none;
}
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body {
  background-color: white;
}
#app {
  font-family: 'Merriweather', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  overflow: hidden;
}

body {
  font-size: 16px;
}
.main {
  margin: 0px;
  background-color: #f5f5f5;
}
a {
  color: black;
  text-decoration: none;
}
.header, .content, .footer {
  padding: 30px;
}
.header {
  text-align: left;
  background-color: #262626;
  color: #EBEBEB;
  padding-bottom: 70px;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.45);
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.45);
}
.header .title {
    text-align: center;
    font-size: 60px;
    margin: 32px;
    margin-bottom: 8px;
    font-family: 'Shrikhand', cursive;
}
.header .subtitle {
    text-align: center;
    white-space: pre-line;
    word-wrap: break-word;
    font-size: 30px;
    margin-left: 32px;
    font-weight: bold;
    font-style: italic;
    line-height: 40px;
    color: #ababab;
}
.header .italic {
    font-style: italic;
}
.header .bold {
    font-weight: bold;
}
.header .bold a {
      text-decoration: underline;
}
.header .small {
    font-size: 14px;
}
.header .description {
    white-space: pre-line;
    word-wrap: break-word;
    margin: 16px auto;
    line-height: 24px;
    max-width: 1000px;
    text-align: justify;
}
.header .copyright {
    margin-right: 32px;
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    text-align: right;
    color: #ababab;
}
.header .anchor-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    max-width: 800px;
    margin: auto;
}
.header .anchor-container .anchor-element {
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      text-align: center;
}
.header .anchor-container .anchor-element .anchor {
        font-weight: bold;
        color: #EBEBEB;
        text-decoration: underline;
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 30px;
}
.header .anchor-container .anchor-element .italic {
        margin-top: 15px;
}
@media (max-width: 750px) {
.header .anchor-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.header .anchor-container .anchor-element {
      margin-bottom: 30px;
}
}
.content {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.footer {
  padding: 30px;
  height: 100px;
  color: #EBEBEB;
  background-color: #262626;
  white-space: pre-line;
}
.footer .left {
    float: left;
    text-align: left;
    line-height: 10px;
}
.footer .left a {
      color: #EBEBEB;
      text-decoration: underline;
}
.footer .right {
    float: right;
    text-align: right;
}
@media (max-width: 512px) {
.title {
    font-size: 40px !important;
}
.container-pub {
    max-width: 100% !important;
    margin: 0px !important;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}
.container-pub .image {
      max-width: 100% !important;
      padding: 0px !important;
}
}
@media (max-width: 350px) {
.title {
    font-size: 30px !important;
}
}
.song {
  max-width: 600px;
  margin: 20px auto;
}
.song p {
    line-height: 22px;
    margin-top: 10px;
}
.flag-container {
  margin-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  letter-spacing: 30px;
}
.flag {
  float: left;
  font-size: 30px;
  cursor: pointer;
}
.backlink {
  color: #EBEBEB;
  text-decoration: underline;
}
.container-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.container-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.container-pub:hover {
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.45);
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.45);
}
.img-container {
  text-align: center;
}
.img-container .image {
    height: auto;
    width: auto;
    max-width: 1000px;
    margin: auto;
    padding-left: 8px;
    padding-right: 8px;
}
@media (max-width: 1000px) {
.img-container .image {
      margin: auto;
      width: 100%;
      clear: both;
}
}
.container-pub {
  max-width: 530px;
  margin: 30px;
  background-color: #FDFDFD;
  color: #262626;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.45);
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.45);
  border-radius: 10px;
  -webkit-transition: -webkit-box-shadow .3s;
  transition: -webkit-box-shadow .3s;
  transition: box-shadow .3s;
  transition: box-shadow .3s, -webkit-box-shadow .3s;
  position: relative;
  text-align: center;
}
.container-pub .title {
    font-size: 30px;
    margin: 24px;
    margin-bottom: 16px;
    font-family: 'Quando', serif;
}
.container-pub .subtitle {
    font-size: 20px;
    font-style: italic;
    margin-bottom: 12px;
    color: #676767;
}
.container-pub .year {
    margin-bottom: 24px;
    color: #676767;
    font-weight: bold;
}
.container-pub .description {
    text-align: justify;
    line-height: 23px;
    margin-bottom: 80px;
    padding: 16px;
}
.container-pub .image {
    height: auto;
    width: auto;
    max-width: 500px;
    max-height: 500px;
    margin: auto;
    padding-left: 8px;
    padding-right: 8px;
}
.container-pub .edition {
    text-align: center;
    font-size: 14 px;
    font-style: italic;
    color: #676767;
    font-weight: bold;
    margin-top: 8px;
}
.container-pub .cta {
    padding-bottom: 20px;
    padding-top: 20px;
    position: absolute;
    background-color: #262626;
    color: #EBEBEB;
    bottom: 0;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-style: bold;
    border-radius: 0px 0px 10px 10px;
    text-decoration: underline;
}
.section-title {
  font-size: 40px;
  margin: 30px;
  margin-top: 50px;
  font-family: 'Shrikhand', cursive;
}
.following {
  margin-top: 100px;
}
.mailto {
  color: #EBEBEB;
  vertical-align: middle;
}
.bold a {
  color: #EBEBEB;
}

body[data-v-587956b9] {
  font-size: 16px;
}
.main[data-v-587956b9] {
  margin: 0px;
  background-color: #f5f5f5;
}
a[data-v-587956b9] {
  color: black;
  text-decoration: none;
}
.header[data-v-587956b9], .content[data-v-587956b9], .footer[data-v-587956b9] {
  padding: 30px;
}
.header[data-v-587956b9] {
  text-align: left;
  background-color: #262626;
  color: #EBEBEB;
  padding-bottom: 70px;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.45);
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.45);
}
.header .title[data-v-587956b9] {
    text-align: center;
    font-size: 60px;
    margin: 32px;
    margin-bottom: 8px;
    font-family: 'Shrikhand', cursive;
}
.header .subtitle[data-v-587956b9] {
    text-align: center;
    white-space: pre-line;
    word-wrap: break-word;
    font-size: 30px;
    margin-left: 32px;
    font-weight: bold;
    font-style: italic;
    line-height: 40px;
    color: #ababab;
}
.header .italic[data-v-587956b9] {
    font-style: italic;
}
.header .bold[data-v-587956b9] {
    font-weight: bold;
}
.header .bold a[data-v-587956b9] {
      text-decoration: underline;
}
.header .small[data-v-587956b9] {
    font-size: 14px;
}
.header .description[data-v-587956b9] {
    white-space: pre-line;
    word-wrap: break-word;
    margin: 16px auto;
    line-height: 24px;
    max-width: 1000px;
    text-align: justify;
}
.header .copyright[data-v-587956b9] {
    margin-right: 32px;
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    text-align: right;
    color: #ababab;
}
.header .anchor-container[data-v-587956b9] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    max-width: 800px;
    margin: auto;
}
.header .anchor-container .anchor-element[data-v-587956b9] {
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      text-align: center;
}
.header .anchor-container .anchor-element .anchor[data-v-587956b9] {
        font-weight: bold;
        color: #EBEBEB;
        text-decoration: underline;
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 30px;
}
.header .anchor-container .anchor-element .italic[data-v-587956b9] {
        margin-top: 15px;
}
@media (max-width: 750px) {
.header .anchor-container[data-v-587956b9] {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.header .anchor-container .anchor-element[data-v-587956b9] {
      margin-bottom: 30px;
}
}
.content[data-v-587956b9] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.footer[data-v-587956b9] {
  padding: 30px;
  height: 100px;
  color: #EBEBEB;
  background-color: #262626;
  white-space: pre-line;
}
.footer .left[data-v-587956b9] {
    float: left;
    text-align: left;
    line-height: 10px;
}
.footer .left a[data-v-587956b9] {
      color: #EBEBEB;
      text-decoration: underline;
}
.footer .right[data-v-587956b9] {
    float: right;
    text-align: right;
}
@media (max-width: 512px) {
.title[data-v-587956b9] {
    font-size: 40px !important;
}
.container-pub[data-v-587956b9] {
    max-width: 100% !important;
    margin: 0px !important;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}
.container-pub .image[data-v-587956b9] {
      max-width: 100% !important;
      padding: 0px !important;
}
}
@media (max-width: 350px) {
.title[data-v-587956b9] {
    font-size: 30px !important;
}
}
.song[data-v-587956b9] {
  max-width: 600px;
  margin: 20px auto;
}
.song p[data-v-587956b9] {
    line-height: 22px;
    margin-top: 10px;
}
.flag-container[data-v-587956b9] {
  margin-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  letter-spacing: 30px;
}
.flag[data-v-587956b9] {
  float: left;
  font-size: 30px;
  cursor: pointer;
}
.backlink[data-v-587956b9] {
  color: #EBEBEB;
  text-decoration: underline;
}
.container-row[data-v-587956b9] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.container-col[data-v-587956b9] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.container-pub[data-v-587956b9]:hover {
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.45);
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.45);
}
.img-container[data-v-587956b9] {
  text-align: center;
}
.img-container .image[data-v-587956b9] {
    height: auto;
    width: auto;
    max-width: 1000px;
    margin: auto;
    padding-left: 8px;
    padding-right: 8px;
}
@media (max-width: 1000px) {
.img-container .image[data-v-587956b9] {
      margin: auto;
      width: 100%;
      clear: both;
}
}
.container-pub[data-v-587956b9] {
  max-width: 530px;
  margin: 30px;
  background-color: #FDFDFD;
  color: #262626;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.45);
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.45);
  border-radius: 10px;
  -webkit-transition: -webkit-box-shadow .3s;
  transition: -webkit-box-shadow .3s;
  transition: box-shadow .3s;
  transition: box-shadow .3s, -webkit-box-shadow .3s;
  position: relative;
  text-align: center;
}
.container-pub .title[data-v-587956b9] {
    font-size: 30px;
    margin: 24px;
    margin-bottom: 16px;
    font-family: 'Quando', serif;
}
.container-pub .subtitle[data-v-587956b9] {
    font-size: 20px;
    font-style: italic;
    margin-bottom: 12px;
    color: #676767;
}
.container-pub .year[data-v-587956b9] {
    margin-bottom: 24px;
    color: #676767;
    font-weight: bold;
}
.container-pub .description[data-v-587956b9] {
    text-align: justify;
    line-height: 23px;
    margin-bottom: 80px;
    padding: 16px;
}
.container-pub .image[data-v-587956b9] {
    height: auto;
    width: auto;
    max-width: 500px;
    max-height: 500px;
    margin: auto;
    padding-left: 8px;
    padding-right: 8px;
}
.container-pub .edition[data-v-587956b9] {
    text-align: center;
    font-size: 14 px;
    font-style: italic;
    color: #676767;
    font-weight: bold;
    margin-top: 8px;
}
.container-pub .cta[data-v-587956b9] {
    padding-bottom: 20px;
    padding-top: 20px;
    position: absolute;
    background-color: #262626;
    color: #EBEBEB;
    bottom: 0;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-style: bold;
    border-radius: 0px 0px 10px 10px;
    text-decoration: underline;
}
.section-title[data-v-587956b9] {
  font-size: 40px;
  margin: 30px;
  margin-top: 50px;
  font-family: 'Shrikhand', cursive;
}
.following[data-v-587956b9] {
  margin-top: 100px;
}
.mailto[data-v-587956b9] {
  color: #EBEBEB;
  vertical-align: middle;
}
.bold a[data-v-587956b9] {
  color: #EBEBEB;
}

body[data-v-2bf43fcf] {
  font-size: 16px;
}
.main[data-v-2bf43fcf] {
  margin: 0px;
  background-color: #f5f5f5;
}
a[data-v-2bf43fcf] {
  color: black;
  text-decoration: none;
}
.header[data-v-2bf43fcf], .content[data-v-2bf43fcf], .footer[data-v-2bf43fcf] {
  padding: 30px;
}
.header[data-v-2bf43fcf] {
  text-align: left;
  background-color: #262626;
  color: #EBEBEB;
  padding-bottom: 70px;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.45);
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.45);
}
.header .title[data-v-2bf43fcf] {
    text-align: center;
    font-size: 60px;
    margin: 32px;
    margin-bottom: 8px;
    font-family: 'Shrikhand', cursive;
}
.header .subtitle[data-v-2bf43fcf] {
    text-align: center;
    white-space: pre-line;
    word-wrap: break-word;
    font-size: 30px;
    margin-left: 32px;
    font-weight: bold;
    font-style: italic;
    line-height: 40px;
    color: #ababab;
}
.header .italic[data-v-2bf43fcf] {
    font-style: italic;
}
.header .bold[data-v-2bf43fcf] {
    font-weight: bold;
}
.header .bold a[data-v-2bf43fcf] {
      text-decoration: underline;
}
.header .small[data-v-2bf43fcf] {
    font-size: 14px;
}
.header .description[data-v-2bf43fcf] {
    white-space: pre-line;
    word-wrap: break-word;
    margin: 16px auto;
    line-height: 24px;
    max-width: 1000px;
    text-align: justify;
}
.header .copyright[data-v-2bf43fcf] {
    margin-right: 32px;
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    text-align: right;
    color: #ababab;
}
.header .anchor-container[data-v-2bf43fcf] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    max-width: 800px;
    margin: auto;
}
.header .anchor-container .anchor-element[data-v-2bf43fcf] {
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      text-align: center;
}
.header .anchor-container .anchor-element .anchor[data-v-2bf43fcf] {
        font-weight: bold;
        color: #EBEBEB;
        text-decoration: underline;
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 30px;
}
.header .anchor-container .anchor-element .italic[data-v-2bf43fcf] {
        margin-top: 15px;
}
@media (max-width: 750px) {
.header .anchor-container[data-v-2bf43fcf] {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.header .anchor-container .anchor-element[data-v-2bf43fcf] {
      margin-bottom: 30px;
}
}
.content[data-v-2bf43fcf] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.footer[data-v-2bf43fcf] {
  padding: 30px;
  height: 100px;
  color: #EBEBEB;
  background-color: #262626;
  white-space: pre-line;
}
.footer .left[data-v-2bf43fcf] {
    float: left;
    text-align: left;
    line-height: 10px;
}
.footer .left a[data-v-2bf43fcf] {
      color: #EBEBEB;
      text-decoration: underline;
}
.footer .right[data-v-2bf43fcf] {
    float: right;
    text-align: right;
}
@media (max-width: 512px) {
.title[data-v-2bf43fcf] {
    font-size: 40px !important;
}
.container-pub[data-v-2bf43fcf] {
    max-width: 100% !important;
    margin: 0px !important;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}
.container-pub .image[data-v-2bf43fcf] {
      max-width: 100% !important;
      padding: 0px !important;
}
}
@media (max-width: 350px) {
.title[data-v-2bf43fcf] {
    font-size: 30px !important;
}
}
.song[data-v-2bf43fcf] {
  max-width: 600px;
  margin: 20px auto;
}
.song p[data-v-2bf43fcf] {
    line-height: 22px;
    margin-top: 10px;
}
.flag-container[data-v-2bf43fcf] {
  margin-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  letter-spacing: 30px;
}
.flag[data-v-2bf43fcf] {
  float: left;
  font-size: 30px;
  cursor: pointer;
}
.backlink[data-v-2bf43fcf] {
  color: #EBEBEB;
  text-decoration: underline;
}
.container-row[data-v-2bf43fcf] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.container-col[data-v-2bf43fcf] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.container-pub[data-v-2bf43fcf]:hover {
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.45);
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.45);
}
.img-container[data-v-2bf43fcf] {
  text-align: center;
}
.img-container .image[data-v-2bf43fcf] {
    height: auto;
    width: auto;
    max-width: 1000px;
    margin: auto;
    padding-left: 8px;
    padding-right: 8px;
}
@media (max-width: 1000px) {
.img-container .image[data-v-2bf43fcf] {
      margin: auto;
      width: 100%;
      clear: both;
}
}
.container-pub[data-v-2bf43fcf] {
  max-width: 530px;
  margin: 30px;
  background-color: #FDFDFD;
  color: #262626;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.45);
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.45);
  border-radius: 10px;
  -webkit-transition: -webkit-box-shadow .3s;
  transition: -webkit-box-shadow .3s;
  transition: box-shadow .3s;
  transition: box-shadow .3s, -webkit-box-shadow .3s;
  position: relative;
  text-align: center;
}
.container-pub .title[data-v-2bf43fcf] {
    font-size: 30px;
    margin: 24px;
    margin-bottom: 16px;
    font-family: 'Quando', serif;
}
.container-pub .subtitle[data-v-2bf43fcf] {
    font-size: 20px;
    font-style: italic;
    margin-bottom: 12px;
    color: #676767;
}
.container-pub .year[data-v-2bf43fcf] {
    margin-bottom: 24px;
    color: #676767;
    font-weight: bold;
}
.container-pub .description[data-v-2bf43fcf] {
    text-align: justify;
    line-height: 23px;
    margin-bottom: 80px;
    padding: 16px;
}
.container-pub .image[data-v-2bf43fcf] {
    height: auto;
    width: auto;
    max-width: 500px;
    max-height: 500px;
    margin: auto;
    padding-left: 8px;
    padding-right: 8px;
}
.container-pub .edition[data-v-2bf43fcf] {
    text-align: center;
    font-size: 14 px;
    font-style: italic;
    color: #676767;
    font-weight: bold;
    margin-top: 8px;
}
.container-pub .cta[data-v-2bf43fcf] {
    padding-bottom: 20px;
    padding-top: 20px;
    position: absolute;
    background-color: #262626;
    color: #EBEBEB;
    bottom: 0;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-style: bold;
    border-radius: 0px 0px 10px 10px;
    text-decoration: underline;
}
.section-title[data-v-2bf43fcf] {
  font-size: 40px;
  margin: 30px;
  margin-top: 50px;
  font-family: 'Shrikhand', cursive;
}
.following[data-v-2bf43fcf] {
  margin-top: 100px;
}
.mailto[data-v-2bf43fcf] {
  color: #EBEBEB;
  vertical-align: middle;
}
.bold a[data-v-2bf43fcf] {
  color: #EBEBEB;
}

body[data-v-6202100b] {
  font-size: 16px;
}
.main[data-v-6202100b] {
  margin: 0px;
  background-color: #f5f5f5;
}
a[data-v-6202100b] {
  color: black;
  text-decoration: none;
}
.header[data-v-6202100b], .content[data-v-6202100b], .footer[data-v-6202100b] {
  padding: 30px;
}
.header[data-v-6202100b] {
  text-align: left;
  background-color: #262626;
  color: #EBEBEB;
  padding-bottom: 70px;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.45);
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.45);
}
.header .title[data-v-6202100b] {
    text-align: center;
    font-size: 60px;
    margin: 32px;
    margin-bottom: 8px;
    font-family: 'Shrikhand', cursive;
}
.header .subtitle[data-v-6202100b] {
    text-align: center;
    white-space: pre-line;
    word-wrap: break-word;
    font-size: 30px;
    margin-left: 32px;
    font-weight: bold;
    font-style: italic;
    line-height: 40px;
    color: #ababab;
}
.header .italic[data-v-6202100b] {
    font-style: italic;
}
.header .bold[data-v-6202100b] {
    font-weight: bold;
}
.header .bold a[data-v-6202100b] {
      text-decoration: underline;
}
.header .small[data-v-6202100b] {
    font-size: 14px;
}
.header .description[data-v-6202100b] {
    white-space: pre-line;
    word-wrap: break-word;
    margin: 16px auto;
    line-height: 24px;
    max-width: 1000px;
    text-align: justify;
}
.header .copyright[data-v-6202100b] {
    margin-right: 32px;
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    text-align: right;
    color: #ababab;
}
.header .anchor-container[data-v-6202100b] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    max-width: 800px;
    margin: auto;
}
.header .anchor-container .anchor-element[data-v-6202100b] {
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      text-align: center;
}
.header .anchor-container .anchor-element .anchor[data-v-6202100b] {
        font-weight: bold;
        color: #EBEBEB;
        text-decoration: underline;
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 30px;
}
.header .anchor-container .anchor-element .italic[data-v-6202100b] {
        margin-top: 15px;
}
@media (max-width: 750px) {
.header .anchor-container[data-v-6202100b] {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.header .anchor-container .anchor-element[data-v-6202100b] {
      margin-bottom: 30px;
}
}
.content[data-v-6202100b] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.footer[data-v-6202100b] {
  padding: 30px;
  height: 100px;
  color: #EBEBEB;
  background-color: #262626;
  white-space: pre-line;
}
.footer .left[data-v-6202100b] {
    float: left;
    text-align: left;
    line-height: 10px;
}
.footer .left a[data-v-6202100b] {
      color: #EBEBEB;
      text-decoration: underline;
}
.footer .right[data-v-6202100b] {
    float: right;
    text-align: right;
}
@media (max-width: 512px) {
.title[data-v-6202100b] {
    font-size: 40px !important;
}
.container-pub[data-v-6202100b] {
    max-width: 100% !important;
    margin: 0px !important;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}
.container-pub .image[data-v-6202100b] {
      max-width: 100% !important;
      padding: 0px !important;
}
}
@media (max-width: 350px) {
.title[data-v-6202100b] {
    font-size: 30px !important;
}
}
.header[data-v-6202100b] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  text-align: left;
}
.header a[data-v-6202100b] {
    text-decoration: underline;
    font-weight: bold;
}
.header .container-col .title[data-v-6202100b] {
    text-align: left;
    font-size: 40px;
    margin-left: 0px;
}
.header .container-col .subtitle[data-v-6202100b] {
    text-align: left;
    font-size: 20px;
    margin-left: 0px;
    font-style: italic;
    margin-bottom: 8px;
    font-weight: normal;
}
.header .container-col .description[data-v-6202100b] {
    margin: 0px;
    margin-top: 16px;
    margin-bottom: 16px;
}
.header .container-col .edition[data-v-6202100b] {
    font-size: 14 px;
    font-style: italic;
    color: #676767;
    margin-top: 8px;
}
.header .image[data-v-6202100b] {
    height: auto;
    width: auto;
    max-width: 300px;
    max-height: 300px;
    margin: auto;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.45);
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.45);
}
.content .image[data-v-6202100b] {
  margin: auto;
  margin-bottom: 32px;
  width: 768px;
  clear: both;
}
@media (max-width: 768px) {
.content .image[data-v-6202100b] {
    margin: auto;
    margin-bottom: 32px;
    width: 100%;
    clear: both;
}
}
.zero[data-v-6202100b] {
  background-color: #edd081;
  color: #262626;
}
.zero .container-col .subtitle[data-v-6202100b], .zero .container-col .year[data-v-6202100b], .zero .container-col .edition[data-v-6202100b] {
    color: #676767;
}
.zero a[data-v-6202100b] {
    color: #262626;
}
.one[data-v-6202100b] {
  background-color: #c85848;
  color: #EBEBEB;
}
.one .container-col .subtitle[data-v-6202100b], .one .container-col .year[data-v-6202100b], .one .container-col .edition[data-v-6202100b] {
    color: #e4e4e4;
}
.one a[data-v-6202100b] {
    color: #EBEBEB;
}
.two[data-v-6202100b] {
  background-color: #c85848;
  color: #EBEBEB;
}
.two .container-col .subtitle[data-v-6202100b], .two .container-col .year[data-v-6202100b], .two .container-col .edition[data-v-6202100b] {
    color: #e4e4e4;
}
.two a[data-v-6202100b] {
    color: #EBEBEB;
}
.three[data-v-6202100b] {
  background-color: #a9a9a9;
  color: #262626;
}
.three .container-col .subtitle[data-v-6202100b], .three .container-col .year[data-v-6202100b], .three .container-col .edition[data-v-6202100b] {
    color: #676767;
}
.three a[data-v-6202100b] {
    color: #262626;
}
.four[data-v-6202100b] {
  background-color: #696c59;
  color: #EBEBEB;
}
.four .container-col .subtitle[data-v-6202100b], .four .container-col .year[data-v-6202100b], .four .container-col .edition[data-v-6202100b] {
    color: #e4e4e4;
}
.four a[data-v-6202100b] {
    color: #EBEBEB;
}
.five[data-v-6202100b] {
  background-color: #262626;
  color: #EBEBEB;
}
.five .container-col .subtitle[data-v-6202100b], .five .container-col .year[data-v-6202100b], .five .container-col .edition[data-v-6202100b] {
    color: #e4e4e4;
}
.five a[data-v-6202100b] {
    color: #EBEBEB;
}

body[data-v-8459d4d6] {
  font-size: 16px;
}
.main[data-v-8459d4d6] {
  margin: 0px;
  background-color: #f5f5f5;
}
a[data-v-8459d4d6] {
  color: black;
  text-decoration: none;
}
.header[data-v-8459d4d6], .content[data-v-8459d4d6], .footer[data-v-8459d4d6] {
  padding: 30px;
}
.header[data-v-8459d4d6] {
  text-align: left;
  background-color: #262626;
  color: #EBEBEB;
  padding-bottom: 70px;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.45);
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.45);
}
.header .title[data-v-8459d4d6] {
    text-align: center;
    font-size: 60px;
    margin: 32px;
    margin-bottom: 8px;
    font-family: 'Shrikhand', cursive;
}
.header .subtitle[data-v-8459d4d6] {
    text-align: center;
    white-space: pre-line;
    word-wrap: break-word;
    font-size: 30px;
    margin-left: 32px;
    font-weight: bold;
    font-style: italic;
    line-height: 40px;
    color: #ababab;
}
.header .italic[data-v-8459d4d6] {
    font-style: italic;
}
.header .bold[data-v-8459d4d6] {
    font-weight: bold;
}
.header .bold a[data-v-8459d4d6] {
      text-decoration: underline;
}
.header .small[data-v-8459d4d6] {
    font-size: 14px;
}
.header .description[data-v-8459d4d6] {
    white-space: pre-line;
    word-wrap: break-word;
    margin: 16px auto;
    line-height: 24px;
    max-width: 1000px;
    text-align: justify;
}
.header .copyright[data-v-8459d4d6] {
    margin-right: 32px;
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    text-align: right;
    color: #ababab;
}
.header .anchor-container[data-v-8459d4d6] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    max-width: 800px;
    margin: auto;
}
.header .anchor-container .anchor-element[data-v-8459d4d6] {
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      text-align: center;
}
.header .anchor-container .anchor-element .anchor[data-v-8459d4d6] {
        font-weight: bold;
        color: #EBEBEB;
        text-decoration: underline;
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 30px;
}
.header .anchor-container .anchor-element .italic[data-v-8459d4d6] {
        margin-top: 15px;
}
@media (max-width: 750px) {
.header .anchor-container[data-v-8459d4d6] {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.header .anchor-container .anchor-element[data-v-8459d4d6] {
      margin-bottom: 30px;
}
}
.content[data-v-8459d4d6] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.footer[data-v-8459d4d6] {
  padding: 30px;
  height: 100px;
  color: #EBEBEB;
  background-color: #262626;
  white-space: pre-line;
}
.footer .left[data-v-8459d4d6] {
    float: left;
    text-align: left;
    line-height: 10px;
}
.footer .left a[data-v-8459d4d6] {
      color: #EBEBEB;
      text-decoration: underline;
}
.footer .right[data-v-8459d4d6] {
    float: right;
    text-align: right;
}
@media (max-width: 512px) {
.title[data-v-8459d4d6] {
    font-size: 40px !important;
}
.container-pub[data-v-8459d4d6] {
    max-width: 100% !important;
    margin: 0px !important;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}
.container-pub .image[data-v-8459d4d6] {
      max-width: 100% !important;
      padding: 0px !important;
}
}
@media (max-width: 350px) {
.title[data-v-8459d4d6] {
    font-size: 30px !important;
}
}
.header[data-v-8459d4d6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  text-align: left;
}
.header a[data-v-8459d4d6] {
    text-decoration: underline;
    font-weight: bold;
}
.header .container-col .title[data-v-8459d4d6] {
    text-align: left;
    font-size: 40px;
    margin-left: 0px;
}
.header .container-col .subtitle[data-v-8459d4d6] {
    text-align: left;
    font-size: 20px;
    margin-left: 0px;
    font-style: italic;
    margin-bottom: 8px;
    font-weight: normal;
}
.header .container-col .description[data-v-8459d4d6] {
    margin: 0px;
    margin-top: 16px;
    margin-bottom: 16px;
}
.header .container-col .edition[data-v-8459d4d6] {
    font-size: 14 px;
    font-style: italic;
    color: #676767;
    margin-top: 8px;
}
.header .image[data-v-8459d4d6] {
    height: auto;
    width: auto;
    max-width: 300px;
    max-height: 300px;
    margin: auto;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.45);
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.45);
}
.content .image[data-v-8459d4d6] {
  margin: auto;
  margin-bottom: 32px;
  width: 768px;
  clear: both;
}
@media (max-width: 768px) {
.content .image[data-v-8459d4d6] {
    margin: auto;
    margin-bottom: 32px;
    width: 100%;
    clear: both;
}
}
.zero[data-v-8459d4d6] {
  background-color: #edd081;
  color: #262626;
}
.zero .container-col .subtitle[data-v-8459d4d6], .zero .container-col .year[data-v-8459d4d6], .zero .container-col .edition[data-v-8459d4d6] {
    color: #676767;
}
.zero a[data-v-8459d4d6] {
    color: #262626;
}
.one[data-v-8459d4d6] {
  background-color: #c85848;
  color: #EBEBEB;
}
.one .container-col .subtitle[data-v-8459d4d6], .one .container-col .year[data-v-8459d4d6], .one .container-col .edition[data-v-8459d4d6] {
    color: #e4e4e4;
}
.one a[data-v-8459d4d6] {
    color: #EBEBEB;
}
.two[data-v-8459d4d6] {
  background-color: #c85848;
  color: #EBEBEB;
}
.two .container-col .subtitle[data-v-8459d4d6], .two .container-col .year[data-v-8459d4d6], .two .container-col .edition[data-v-8459d4d6] {
    color: #e4e4e4;
}
.two a[data-v-8459d4d6] {
    color: #EBEBEB;
}
.three[data-v-8459d4d6] {
  background-color: #a9a9a9;
  color: #262626;
}
.three .container-col .subtitle[data-v-8459d4d6], .three .container-col .year[data-v-8459d4d6], .three .container-col .edition[data-v-8459d4d6] {
    color: #676767;
}
.three a[data-v-8459d4d6] {
    color: #262626;
}
.four[data-v-8459d4d6] {
  background-color: #696c59;
  color: #EBEBEB;
}
.four .container-col .subtitle[data-v-8459d4d6], .four .container-col .year[data-v-8459d4d6], .four .container-col .edition[data-v-8459d4d6] {
    color: #e4e4e4;
}
.four a[data-v-8459d4d6] {
    color: #EBEBEB;
}
.five[data-v-8459d4d6] {
  background-color: #262626;
  color: #EBEBEB;
}
.five .container-col .subtitle[data-v-8459d4d6], .five .container-col .year[data-v-8459d4d6], .five .container-col .edition[data-v-8459d4d6] {
    color: #e4e4e4;
}
.five a[data-v-8459d4d6] {
    color: #EBEBEB;
}
